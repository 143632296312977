<template>
  <v-card class="card mx-auto" max-width="700" outlined>
    <v-list-item three-line>
      <v-list-item-content>
        <div class="text-overline mb-4">GLOW</div>
        <v-list-item-title class="text-h5 mb-1">
          كلمة المرور الخاصة بتجار الجملة

          <b
            class="jomla_code"
            v-if="
              old_jomla_code == 'undefined' ||
              old_jomla_code == 'null' ||
              old_jomla_code == ''
            "
            >لم تقم بأضافة كود</b
          >
          <b class="jomla_code" v-else>{{ old_jomla_code[0].jomla_code }}</b>
        </v-list-item-title>
        <v-list-item-subtitle>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="jomla_code"
              placeholder="كلمة المرور الخاصة بتجار الجملة"
              label="كلمة المرور الخاصة بتجار الجملة"
              hide-details="auto"
              clearable
            ></v-text-field>
          </v-col>
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>

    <v-card-actions>
      <v-btn text class="addJomlaCode" @click="addJomlaCode">
        أضف كلمة مرور جديدة
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
export default {
  data() {
    return {
      jomla_code: "",
    };
  },
  computed: {
    old_jomla_code() {
      return this.$store.state.Settings.jomla_code;
    },
  },
  methods: {
    addJomlaCode() {
      console.log(this.jomla_code);
      let data = {
        jomla_code: this.jomla_code,
      };
      this.$store.dispatch("Settings/addJomlaCode", data);
    },
    getJomlaCode() {
      this.$store.dispatch("Settings/getJomlaCode");
    },
  },
  watch: {
    pagination: {
      handler() {
        this.getJomlaCode();
      },
      deep: true,
    },
  },
  created() {
    this.getJomlaCode();
  },
};
</script>

<style>
.card {
  background-color: #f5f5f5;
  border-radius: 30px;
  padding: 60px;
  margin-top: 200px;
}

.addJomlaCode {
  background-color: rgb(27, 17, 17);
  color: white !important;
  font-size: 20px;
  font-weight: 600;
  padding: 10px 20px;
  border-radius: 20px;
  border: 1px solid #4a33b4;
  margin-top: 20px;
}
.jomla_code {
  font-size: 26px;
  font-weight: 600;
  color: #cd2742;
  margin: 10px;
}
</style>
