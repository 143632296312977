<template>
  <div>
    <link
      rel="stylesheet"
      href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css"
      integrity="sha384-Gn5384xqQ1aoWXA+058RXPxPg6fy4IWvTNh0E263XmFcJlSAwiGgFAW/dAiS6JXm"
      crossorigin="anonymous"
    />

    <div class="loginPage">
      <div class="form">
        <h2>تسجيل الدخول</h2>

        <v-alert v-if="alert" class="text-right" type="error">
          {{ alertText }}</v-alert
        >

        <form @submit.prevent="login">
          <div class="form-group text-right">
            <label for="exampleInputEmail1 ">اسم المستخدم</label>
            <input
              type="text"
              class="form-control"
              id="username"
              aria-describedby="emailHelp"
              v-model="phone_number"
            />
          </div>
          <div class="form-group text-right">
            <label for="exampleInputEmail1">كلمة المرور</label>
            <input
              type="password"
              class="form-control"
              id="password"
              aria-describedby="emailHelp"
              v-model="password"
            />
          </div>
          <button type="submit" class="btn btn-primary btn-block">
            تسجيل دخول
          </button>
        </form>
      </div>
      <div class="lotte">
        <lottie-animation
          style="width: 100%"
          path="lottie/Animation - 1708884724650.json"
        />
      </div>
    </div>
  </div>
</template>
<script>
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue"; // import lottie-vuejs

export default {
  data: () => ({
    rules: [(value) => !!value || "هذا الحقل مطلوب."],
    password: "",
    phone_number: "",
    alert: false,
    alertText: "",
  }),
  components: {
    LottieAnimation,
  },
  computed: {},

  methods: {
    login() {
      if (this.phone_number == "" || this.password == "") {
        this.alert = true;
        this.alertText = "يجب ادخال المعلومات";
        // console.log(this.alertText);
        setTimeout(() => {
          this.alert = false;
          this.alertText = "";
        }, 3000);
      }
      this.$store
        .dispatch("login", {
          phone_number: this.phone_number,
          password: this.password,
        })
        .then((res) => {
          // console.log(res);
          let token = localStorage.token;
          if (token == undefined) {
            this.$router.replace("/login");
          } else {
            this.$router.replace("/");
          }
        })
        .catch((err) => {
          this.alert = true;
          this.alertText = "يرجى ادخال معلومات صالحة للتسجيل";
          setTimeout(() => {
            this.alert = false;
          }, 3000);
          // console.log(err);
        });
      this.phone_number = "";
      this.password = "";
    },

    changeRTL() {
      this.$vuetify.rtl = true;
    },
  },
};
</script>
<style>
.loginPage {
  display: flex;
  direction: rtl;
  justify-content: space-around;
  margin: 10px;
  min-height: 700px;
  background-color: #fff !important;
}

.loginPage .form {
  margin-top: 120px;
  margin-right: 40px;
  width: 500px;
  height: 300px;
  padding: 20px;
}
.loginPage .form h2 {
  text-align: center;
  padding: 15px;
}

.loginPage .form .btn {
  margin-top: 50px;
}
.loginPage .lotte {
  width: 50%;
  padding: 15px;
}
.loginPage .lotte img {
  width: 100%;
}
</style>
