<template>
  <v-container>
    <v-form ref="form" class="mr-10">
      <v-col cols="12" md="12" lg="12">
        <v-row>
          <v-col cols="12" sm="4">
            <!-- <v-combobox
              :items="products"
              label="اختر المنتجات"
              multiple
              small-chips
              deletable-chips
              :value="arr"
            >
              <template v-slot:item="{ item }">
                <v-list-item-content @click.stop="multipleSelection(item)">{{
                  item.name_ar
                }}</v-list-item-content>
              </template>
              <template v-slot:selection="{ item, index }">
                <v-chip
                  close
                  secondary
                  color="info"
                  @click:close="deleteChip(index)"
                  >{{ item.name_ar }}</v-chip
                >
              </template>
            </v-combobox> -->
            <v-autocomplete
              clearable
              multiple
              :items="products"
              chips
              closable-chips
              ref="productsDropdown"
              v-model="products_id"
              item-text="name_ar"
              item-value="id"
              label="اختر منتج"
              :loading="loading"
              :menu-props="menu_props"
              :search-input.sync="productQuery"
              :value="productQuery"
            >
              <template v-slot:append-item>
                <div
                  v-if="!($store.state.Product.product_state == 'finished')"
                  v-intersect="productInteract"
                  class="text-xs-center"
                ></div>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="12" sm="4">
            <v-text-field
              v-model="selected_object.name"
              placeholder="اسم الروتين "
              label="اسم الروتين "
              hide-details="auto"
              :rules="rules"
              clearable
            ></v-text-field>
          </v-col>
          <v-col cols="4">
            <div
              id="my-strictly-unique-vue-upload-multiple-image"
              style="display: flex; justify-content: center"
            >
              <span class="hint_image">اختر صورة للروتين :</span>
              <vue-upload-multiple-image
                @upload-success="uploadImageSuccess"
                @before-remove="beforeRemove"
                @edit-image="editImage"
                :data-images="images"
                maxImageSize="5"
              ></vue-upload-multiple-image>
            </div>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <v-row justify="center">
              <v-col cols="auto">
                <v-btn secondary color="secondary" @click="validateField">
                  {{ isUpdate ? "تعديل القيد" : "أضافة قيد" }}
                </v-btn>
              </v-col>
              <v-col cols="auto">
                <v-btn secondary color="secondary" @click="reset">
                  تصفير الحقول
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-form>
  </v-container>
</template>
<script>
import VueUploadMultipleImage from "vue-upload-multiple-image";

export default {
  data() {
    return {
      name: "",
      menu: null,
      menu_props: {
        closeOnClick: false,
        closeOnContentClick: false,
        disableKeys: true,
        openOnClick: false,
        maxHeight: 150,
        offsetY: true,
        offsetOverflow: true,
        transition: false,
      },
      arr: [],
      images: [],
      upload: [],
      products_id: [],
      isUpdate: false,
      rules: [(v) => !!v || "اسم الماركة مطلوب"],
    };
  },
  components: {
    VueUploadMultipleImage,
  },
  computed: {
    products() {
      return this.$store.state.Product.products;
    },
    selected_object() {
      return this.$store.state.Routen.selected_object;
    },
    isEdit() {
      return this.$store.state.Routen.isEdit;
    },
    loading() {
      return this.$store.state.Product.table_loading;
    },
    productQuery: {
      get() {
        return this.$store.state.Product.productQuery;
      },
      set(value) {
        this.$store.state.Product.productQuery = value;
      },
    },
  },
  methods: {
    uploadImageSuccess(formData, index, fileList) {
      this.upload = [];
      fileList.forEach((element) => {
        let img = element.path;
        this.upload.push(img);
      });
      console.log(fileList);
    },
    beforeRemove(index, done, fileList) {
      console.log("index", index, fileList);
      var r = confirm("remove image");
      if (r == true) {
        done();
      }
    },
    editImage(formData, index, fileList) {
      console.log("edit data", formData, index, fileList);
    },
    multipleSelection(item) {
      this.arr.push({ ...item });
      console.log(this.arr);
    },
    deleteChip(index) {
      this.arr.splice(index, 1);
      this.products_id.splice(index, 1);
    },

    updateProductSearch(value) {
      clearTimeout(this._timerId);
      // delay new call 1000ms
      this._timerId = setTimeout(() => {
        // console.log(this.userQuery);

        if (this.productQuery == null) {
          return;
        }
        // this.$store.dispatch("UserModule/resetFields");
        this.$store.state.Product.params.page = 1;
        // console.log(this.$store.state.UserModule.params);

        if (
          this.productQuery.length == 0 ||
          (this.productQuery.length == 1 && this.productQuery == " ")
        )
          this.productQuery = value.replace(/\s/g, "");
        // هاي تلغي ال space
        else {
          this.$store.state.Product.productQuery = value;
          // console.log(value);
          // console.log(this.$store.state.UserModule.productQuery);
        }
        // this.$store.state.UserModule.params.query = this.productQuery;
        // this.$store.dispatch("UserModule/resetFields");

        this.$store.dispatch("Product/getProducts");
      }, 1000);
    },
    productInteract(entries, observer, isIntersecting) {
      if (isIntersecting) {
        setTimeout(() => {
          this.$store.dispatch("Product/getProducts");

          console.log("on scroll");
          this.$refs.productsDropdown.onScroll();
        }, 500);
      }
    },
    validateField() {
      if (this.$refs.form.validate()) {
        let data = {};
        data["name"] = this.selected_object.name;
        if (this.upload[0] != null) {
          data["image"] = this.upload[0];
        }

        this.arr.forEach((element) => {
          this.products_id.push(element.id);
        });
        data["products_id"] = this.products_id;

        console.log(data);

        if (this.isUpdate) {
          data["id"] = this.selected_object.id;
          this.editRouten(data);
        } else {
          this.addRouten(data);
        }
      }
    },
    addRouten(data) {
      this.$store.dispatch("Routen/addRouten", data);
      this.reset();
    },
    editRouten(data) {
      console.log(data);
      this.$store.dispatch("Routen/editRouten", data);
      this.reset();
    },
    reset() {
      this.$refs.form.reset();
      this.arr = [];
      this.products_id = [];
      this.upload = [];
      this.images = [];
      this.isUpdate = false;
      this.$store.state.Products.isEdit = false;
    },
  },

  created() {
    this.$store.dispatch("Product/getProducts");
  },
  watch: {
    productQuery: function () {
      this.updateProductSearch(this.productQuery);
    },
  },
};
</script>
<style>
.hint {
  font-size: 12px;
  color: #ff0000;
  top: -20px !important;
  position: relative;
}
.button_submit {
  position: relative;
  top: -50px !important;
  right: 100px;
}
</style>
