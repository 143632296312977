<template>
  <v-container>
    <div id="root">
      <div class="container pt-5">
        <div class="row align-items-stretch">
          <div class="c-dashboardInfo col-lg-4 col-md-6">
            <div class="wrap">
              <h4
                class="heading heading5 hind-font medium-font-weight c-dashboardInfo__title"
              >
                المستخدمين المفرد
              </h4>
              <span class="hind-font caption-12 c-dashboardInfo__count">{{
                statistics[0].single_users
              }}</span>
              <div class="icon">
                <i class="fa fa-user-times" aria-hidden="true"></i>
              </div>
            </div>
          </div>
          <div class="c-dashboardInfo col-lg-4 col-md-6">
            <div class="wrap">
              <h4
                class="heading heading5 hind-font medium-font-weight c-dashboardInfo__title"
              >
                المستخدمين الجملة
              </h4>
              <span class="hind-font caption-12 c-dashboardInfo__count">{{
                statistics[0].jomla_users
              }}</span>
              <!-- <i class="fas fa-chalkboard-teacher"></i> -->

              <div class="icon">
                <i class="fa fa-users" aria-hidden="true"></i>
              </div>
            </div>
          </div>
          <div class="c-dashboardInfo col-lg-4 col-md-6">
            <div class="wrap">
              <h4
                class="heading heading5 hind-font medium-font-weight c-dashboardInfo__title"
              >
                الطلبات خلال اخر شهر
              </h4>
              <span class="hind-font caption-12 c-dashboardInfo__count">{{
                statistics[0].order_month
              }}</span>
              <!-- <font-awesome-icon icon="fa-solid fa-screen-users" /> -->
              <div class="icon">
                <i class="fa fa-balance-scale" aria-hidden="true"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
      <v-divider></v-divider>
      <v-card class="mb-5">
        <v-toolbar dark color="primary_theme">
          <v-toolbar-title align-center>
            المنتجات الأكثر طلباً اخر 30 يوم</v-toolbar-title
          >
        </v-toolbar>
        <v-card-text>
          <template>
            <v-simple-table>
              <template v-slot:default>
                <thead style="background: #f5f5f5">
                  <tr>
                    <th style="font-size: 22px">أسم المنتج</th>
                    <th style="font-size: 22px">عدد مرات الشراء</th>
                    <th style="font-size: 22px">سعر المفرد</th>
                    <th style="font-size: 22px">سعر الجملة</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in products" :key="item.name">
                    <td class="body_table">{{ item.name_ar }}</td>
                    <td class="body_table">{{ item.total_sold }}</td>
                    <td class="body_table">{{ item.single_price }}</td>
                    <td class="body_table">{{ item.jomla_price }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </template>
        </v-card-text>
      </v-card>
    </div>
  </v-container>
</template>

<script>
// @ is an alias to /src

export default {
  name: "Home",
  data() {
    return {
      data: {
        jomla_users: 0,
        single_users: 0,
        order_month: 0,
      },
    };
  },
  computed: {
    statistics() {
      return this.$store.state.statistics;
    },
    products() {
      return this.$store.state.statistics[0].products;
    },
  },
  methods: {
    getStatistics() {
      this.$store.dispatch("getStatistics");
    },
  },
  created() {
    this.getStatistics();
  },

  //   watch: {
  //     statistics: function () {
  //       this.statistics.forEach((element) => {
  //         this.data["single_users"] = element.single_users;
  //         this.data["jomla_users"] = element.jomla_users;
  //         this.data["order_month"] = element.order_month;
  //       });

  //       console.log(this.data);
  //     },
  //   },
};
</script>
<style>
.c-dashboardInfo {
  margin-bottom: 15px;
}
.c-dashboardInfo .wrap {
  background: #ffffff;
  box-shadow: 2px 10px 20px rgba(0, 0, 0, 0.1);
  border-radius: 7px;
  text-align: center;
  position: relative;
  overflow: hidden;
  padding: 40px 25px 20px;
  height: 100%;
}
.c-dashboardInfo__title,
.c-dashboardInfo__subInfo {
  color: #6c6c6c;
  font-size: 1.18em;
}
.c-dashboardInfo span {
  display: block;
}
.c-dashboardInfo__count {
  font-weight: 600;
  font-size: 2.5em;
  line-height: 64px;
  color: #323c43;
}
.c-dashboardInfo .wrap:after {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 10px;
  content: "";
}

.c-dashboardInfo:nth-child(1) .wrap:after {
  background: linear-gradient(82.59deg, #00c48c 0%, #7b0db6 100%);
}
.c-dashboardInfo:nth-child(2) .wrap:after {
  background: linear-gradient(81.67deg, #0084f4 0%, #1a4da2 100%);
}
.c-dashboardInfo:nth-child(3) .wrap:after {
  background: linear-gradient(69.83deg, #e20780 0%, #550303 100%);
}
.c-dashboardInfo:nth-child(4) .wrap:after {
  background: linear-gradient(81.67deg, #ff647c 0%, #1f5dc5 100%);
}
.c-dashboardInfo__title svg {
  color: #d7d7d7;
  margin-left: 5px;
}
.MuiSvgIcon-root-19 {
  fill: currentColor;
  width: 1em;
  height: 1em;
  display: inline-block;
  font-size: 24px;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  user-select: none;
  flex-shrink: 0;
}
.body_table {
  text-align: right;
  line-height: 30px;
  font-size: 28px;
  font-weight: 600;
}
</style>
