import Vue from "vue";
import axios from "axios";

const Settings = {
    namespaced: true,
    state: () => ({
        jomla_code: [],
        setting_state: "done",
    }),
    mutations: {
        jomlaCode_success(state, jomla_code) {
            console.log(jomla_code);
            state.jomla_code.splice(0, state.jomla_code.length)
            jomla_code.forEach(element => {
                state.jomla_code.push(element)
            });
            state.setting_state = "done"
        },
        setting_request(state) {
            state.setting_state = "loading";
        },

        setting_error(state) {
            state.setting_state = "error";
        },

        add_jomlaCode_success(state, code) {

            state.jomla_code.unshift(code);
            state.setting_state = "done";

        },


    },
    actions: {
        async resetFields({ state }) {
            state.setting_state = "done";
            state.brands = [];

        },
        async getJomlaCode({ commit, state, dispatch, rootState }) {
            if (state.setting_state != "done") return -1;
            return new Promise((resolve, reject) => {
                axios({
                    url: `${rootState.server}` + "/api/get_jomla_code",
                    method: "GET",
                }).then(resp => {
                    commit('jomlaCode_success', resp.data.result)
                    dispatch("snackbarToggle", { toggle: true, text: resp.data.message }, { root: true });
                    resolve(resp);
                })
            })

        },

        async addJomlaCode({ commit, state, dispatch, rootState }, data) {

            state.table_loading = true
            return new Promise((resolve) => {
                commit("setting_request");
                axios({
                    url: `${rootState.server}` + "/api/add_code_jomla",
                    data: data,
                    headers: {
                        "Content-Type": "application/json",
                    },
                    method: "POST",
                }).then(resp => {
                    state.table_loading = false;
                    commit("add_jomlaCode_success", resp.data.result[0]);
                    dispatch(
                        "snackbarToggle",
                        { toggle: true, text: resp.data.message },
                        { root: true }
                    );
                    resolve(resp);
                }).catch((err) => {
                    state.table_loading = false;
                    commit("setting_error");
                    dispatch(
                        "snackbarToggle",
                        { toggle: true, text: err.response.data.message },
                        { root: true }
                    );

                    console.warn(err);
                });
            });
        },
    }

}
export default Settings;
